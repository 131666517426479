function About() {
    return(
    <div>
        <h1>About me</h1>
    
        <p>Hi! My name is Piotr but all my friends just call me Pete. I am a third year computing student based in Dublin.
            My hobbies include music, airsoft and F1.
        </p>
    </div>
    )
}

export default About;