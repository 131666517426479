import './App.css';

function App() {
  return (
    <div>
      <h1>Hi! This site is still in development but feel free to look around :).</h1>
      <nav class = 'navbar'>
        <ul class='nav__links'>
          <li a href='#'>About Me</li>
          <li a href='#'>Projects</li>
          <li a href='#'>Contact</li>
        </ul>
      </nav>
    </div>
  );
  
}

export default App;
