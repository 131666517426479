function Contact(){
    return (
        <div>
            <h1>Contact me</h1>
            <p>If you'd like to contact me feel free to reach out to me on LinkedIn. 
                You can find me by pressing <a href="https://www.linkedin.com/in/piotr-kimmel-91a254232/">HERE!</a></p>
        </div>
    )
}

export default Contact;