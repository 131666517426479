function Projects(){
    return (
        <div>
            <h1>Projects</h1>

            <p>This section will be filled in soon!</p>
        </div>
    )
}

export default Projects;